<template>
  <div class="box">
    <div class="header">
      <div class="whhh">
        <div class="header_img">
          <img
            @click="dialogVisible = true"
            class="header_imgs"
            src="../../assets/shangwuhezuo_banner.png"
            alt=""
          />
        </div>
      </div>
      <div style="height: 530px"></div>
    </div>
    <div class="brief">
      <div class="w10">
        职培通诚邀合作伙伴 合作热线：400-100-2925 欢迎咨询！
      </div>
    </div>
    <div class="bj">
      <div class="w10">
        <div class="title">为什么要成为职培通的合作伙伴</div>
      </div>
      <div class="w">
        <div class="list">
          <div class="item">
            <div class="title1">新时代：职业培训转型</div>
            <div class="brief1">
              2019年国家推广在线学习提升职业技能，给在线学习平台带来数百亿市场。
            </div>
          </div>
          <div class="item">
            <div class="title1">新机遇：平台喜获官方认可</div>
            <div class="brief1">
              职培通是国家人社部推荐全国线上培训平台·上海市首批“互联网+职业技能培训”线上平台
            </div>
          </div>
          <div class="item">
            <div class="title1">新生态：职培通资源开放</div>
            <div class="brief1">
              开放职培通生态资源，一站式互联全网优质培训资源与企业应用
              共建企业数字化升级服务新生态
            </div>
          </div>
          <div class="item">
            <div class="title1">新培训：专业的赋能转型</div>
            <div class="brief1">
              企业数智化培训服务商 一键订阅SaaS系统，提供线上线下培训服务
            </div>
          </div>
        </div>
        <div class="title">合作省市分布图</div>
        <img src="../../assets/ditu.png" class="img" alt="" />
        <div class="title">合作方式</div>
        <div class="cooperationlist">
          <div class="cooperationlistitem">
            <div class="cooperation_title">师资合作</div>
            <div class="cooperation_introduce">
              寻找优秀的师资资源，为企业用户提供更专业的培训以及定制课程
            </div>
          </div>
          <div class="cooperationlistitem">
            <div class="cooperation_title">课程合作</div>
            <div class="cooperation_introduce">
              寻找优质的课程内容提供商，全方位满足企业用户和个人的学习需求，涵盖管理、销售、HR、营销、财务、职业化、行业课等精品课
            </div>
          </div>
          <div class="cooperationlistitem">
            <div class="cooperation_title">代理合作</div>
            <div class="cooperation_introduce">
              寻找符合要求的机构、院校、社会组织等渠道资源，达成合作意向满足企业培训需求
            </div>
          </div>

          <div class="cooperationlistitem">
            <div class="cooperation_title">品牌合作</div>
            <div class="cooperation_introduce">
              寻找优质合作伙伴，共同拓展市场，服务企业用户和个人的技能提升。
              加入合作
            </div>
          </div>
          <div class="cooperationlistitem">
            <div class="cooperation_title">行业合作</div>
            <div class="cooperation_introduce">
              寻找行业协会，利用优质的培训资源，为行业所属企业提供技能培训服务
            </div>
          </div>
        </div>
        <div class="title">合作流程</div>
        <div class="step">
          <div class="item">
            <div class="item_1">1</div>
            <div class="item_2">提交合作申请</div>
          </div>
          <div class="xian"></div>
          <div class="item">
            <div class="item_1">2</div>
            <div class="item_2">确认合作内容与事项</div>
          </div>
          <div class="xian"></div>
          <div class="item">
            <div class="item_1">3</div>
            <div class="item_2">伙伴资质审核认证</div>
          </div>
          <div class="xian"></div>
          <div class="item">
            <div class="item_1">4</div>
            <div class="item_2">签署协议达成合作</div>
          </div>
        </div>
        <div class="title">加入我们</div>
        <div class="brief_1">
          欢迎加入职培通平台合作伙伴，携手企业数智化培训转型
        </div>
        <div class="joinus">
          <div class="joinus_title">加入我们 未来可期</div>
          <div class="joinus_list">
            <div class="joinus_item">
              <div class="joinus_item_1">
                机构名称<input
                  v-model="form.title"
                  style="
                    margin-left: 15px;
                    padding: 0 15px;
                    box-sizing: border-box;
                    width: 377px;
                    height: 43px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 0px none;
                  "
                  placeholder="请输入机构名称"
                  type="text"
                />
              </div>
            </div>
            <div class="joinus_item">
              <div class="joinus_item_1">
                机构所在地区
                <el-cascader
                  style="width: 404px; height: 43px"
                  v-model="form.address"
                  :options="citylist"
                  placeholder="请选择机构所在地区"
                  :props="props"
                ></el-cascader>
              </div>
            </div>
            <div class="joinus_item">
              <div class="joinus_item_1">
                联系人及联系电话<input
                  v-model="form.name"
                  style="
                    margin-left: 15px;
                    padding: 0 15px;
                    box-sizing: border-box;
                    width: 124px;
                    height: 43px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 0px none;
                  "
                  placeholder="请输入联系人"
                  type="text"
                />
                <input
                  v-model="form.phone"
                  style="
                    margin-left: 15px;
                    padding: 0 15px;
                    box-sizing: border-box;
                    width: 143px;
                    height: 43px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 0px none;
                  "
                  placeholder="请输入联系电话"
                  type="text"
                />
              </div>
            </div>
            <div class="joinus_item">
              <div class="joinus_item_1">
                合作意向<input
                  v-model="form.content"
                  style="
                    margin-left: 15px;
                    padding: 0 15px;
                    box-sizing: border-box;
                    width: 404px;
                    height: 43px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 0px none;
                  "
                  placeholder="请输入合作意向"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="btn" @click="submit">提交</div>
        </div>
      </div>
    </div>
    <el-dialog
      top="45vh"
      title="立即咨询"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div style="text-align: center">
        <img
          class="dialog_img"
          src="../../assets/kefu_erweima_imgs.jpg"
          alt=""
        />
      </div>
      <div style="text-align: center; margin-top: 20px; font-size: 16px">
        400-100-2925
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { city } from "../../libs/city";
export default {
  data() {
    return {
      dialogVisible: false,
      citylist: [],
      props: {
        children: "children",
        label: "label",
        value: "label",
      },

      form: {
        phone: "", //联系电话
        name: "", //名称
        title: "", //机构名称
        address: "", //联系地址
        content: "", //合作意向
      },
    };
  },
  methods: {
    ...mapActions(["getinsJoinUs"]),
    popup() {
      window.location.href =
        "https://www.zpton.com/allcourses?type=1&name=%E8%99%8E%E5%B9%B4%E5%B0%B1%E5%9C%B0%E8%BF%87%C2%B7%E6%8A%80%E8%83%BD%E5%85%8D%E8%B4%B9%E5%AD%A6&id=234&is_type=2";
    },
    message() {
      this.$message({
        message: "信息请填写完整",
        type: "warning",
      });
    },
    submit() {
      if (this.form.phone == "") return this.message();
      if (this.form.name == "") return this.message();
      if (this.form.title == "") return this.message();
      if (this.form.address.length != 3) return this.message();
      if (this.form.content == "") return this.message();
      if (!/^1[3456789]\d{9}$/.test(this.form.phone)) {
        this.$message({
          offset: 100,
          type: "cancel",
          message: "请输入正确的手机号",
        });
        return;
      }
      let arr = this.form.address.join("/");
      this.getinsJoinUs({
        phone: this.form.phone, //联系电话
        name: this.form.name, //名称
        title: this.form.title, //机构名称
        address: arr, //联系地址
        content: this.form.content, //合作意向
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            offset: 100,
            message: "信息提交成功，请耐心等待",
            type: "success",
          });
        }
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.citylist = city;
  },
};
</script>

<style lang="scss" scoped>
input::placeholder {
  color: #d0d0d0;
}
input:focus {
  outline: none;
  border-color: #43bcab;
}
.header_imgs {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 74px;
}
.el-cascader .el-input .el-input__inner {
  height: 43px;
}
.box {
  overflow: hidden;
  position: relative;
}
.headerimg {
  position: absolute;
  left: 50%;
  height: 530px;
  width: 1920px;
  transform: translateX(-50%);
  vertical-align: bottom;
}
.bj {
  overflow: hidden;
  background: url("../../assets/beijingtu.png");
}
.brief {
  height: 69px;
  font-size: 16px;
  line-height: 69px;
  background: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(221, 221, 221, 0.5);
  border-radius: 2px;
}
.title {
  margin: 91px 0 56px 0;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    padding: 31px 17px;
    box-sizing: border-box;
    border: 1px solid #f7f3f3;
    width: 447px;
    height: 142px;

    .title1 {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .brief1 {
      margin-top: 10px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #a5a5a5;
    }
  }
}
.img {
  width: 100%;
}
.cooperationlist {
  display: flex;
  flex-wrap: wrap;

  .cooperationlistitem {
    &:nth-child(3n) {
      margin-right: 0px;
    }
    padding: 29px 20px;
    box-sizing: border-box;
    margin-right: 15px;
    margin-bottom: 17px;
    width: 390px;
    height: 210px;
    background: #ffffff;
    box-shadow: 0px 2px 9px 0px rgba(210, 210, 210, 0.5);
    .cooperation_title {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .cooperation_introduce {
      margin-top: 13px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #999999;
    }
  }
}
.step {
  margin-bottom: 98px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .xian {
    flex: 1;
    height: 3px;
    background-color: #3488f8;
  }
  .item {
    padding: 19px;
    box-sizing: border-box;
    width: 200px;
    height: 118px;
    background: #3488f8;

    border-radius: 10px;

    .item_1 {
      border-radius: 50%;
      margin: 0 auto;
      text-align: center;
      line-height: 31px;
      width: 31px;
      height: 31px;
      background: #ffffff;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1276fb;
    }
    .item_2 {
      margin-top: 25px;
      text-align: center;
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
.brief_1 {
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #777777;
  margin-bottom: 28px;
}
.joinus {
  margin-bottom: 174px;
  padding: 58px 27px;
  box-sizing: border-box;
  height: 411px;
  background: linear-gradient(360deg, #ffffff 0%, #2583fa 100%);
  border-radius: 2px;

  .joinus_title {
    margin-bottom: 39px;
    text-align: center;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
  .joinus_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .joinus_item {
      margin-bottom: 24px;
      .joinus_item_1 {
        font-size: 23px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
  .btn {
    cursor: pointer;
    margin: 51px auto 0;
    text-align: center;
    line-height: 54px;
    width: 340px;
    height: 54px;
    background: #3488f8;
    border-radius: 2px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>
